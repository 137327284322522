/*
 * Flex UX Framework
 *
 * Filename: item.scss
 * Type:     Template Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

.product-detail {
	
	main{
		position: unset;
		z-index: unset;
		background: #F9F9F8;
	}

	.page-header {
		padding-top: 0px;
		margin-top: $header-height;
        @include mq("tablet", "max") {
            margin-top: $header-tablet-height;
        }
	}

	&-page-container {
    	width: 100%;
    	padding: 0;
    	max-width: 1115px;
    	margin: 25px auto 12px;
    	font-family: $standard-font-face;

    	@include mq("tablet", "max"){
    		flex-direction: column;
		    width: calc(100% - 20px);
		    margin-top: 10px;
		    margin: 10px auto 6px;
		}
	}

	&-panel {
    	// border-radius: 5px;
    	padding: 0;
    	background: none;
    	display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		font-family: $bold-font-face;  

		@include mq("tablet", "max"){
			flex-direction:column;
		}
	}

	.product-main-container {
		@include mq("tablet", "min") {
			margin-right: 25px;
    		width: 600px;
    	}
	}

	.product-details-container {
    	width: calc(100% - 625px);
    	background: white;
    	padding: 20px;
    	box-sizing: border-box;
    	height: 100%;  
		

    	@include mq("tablet", "max"){
    		width: 100%;
    		margin-top: 10px;
    	}

    	.select-option-btn:after {
		    font-size: 24px;
		}

		.share {
			padding: 30px 0 20px;
			border: 1px solid rgba(226, 226, 226, 0.5);
			border-left: none;
			border-right: none;
			display: flex; 
		}
	}

}