/*
 * Flex UX Framework
 *
 * Filename: checkout-addresses-shipping.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.checkout-address-list {
    margin-top: 0;
    padding-top: 0;
   
    &__item {
    	border-bottom: 1px solid #efeff1;

    	&:last-child {
    		border-bottom: 0;
    	}
    	 color: $primary-font-color; 
    }

    &__description {
      
    	cursor: pointer;
		padding-left: 5px;
		font-size: 14px;
		line-height: 1.15em; 
		letter-spacing: 0;
		font-family: $secondary-font-face; 
		 color: $primary-font-color;

		strong {
            font-family: $bold-font-face;
            font-weight: 500!important;
		}
    }
}
