/*
 * Flex UX Framework
 *
 * Filename: category.scss
 * Type:     Template Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.category {
	
}

.category-list {
	main{
		background-color: #F9F9F8; 
	}

	.page-header {
		height: 0px!important;

		@include mq("phone-smallest", "min") {
			padding-top: calc(0px + 0px);
		}		

		@include mq("phone-wide", "min") {
			padding-top: calc(55px + 0px);
		}			

		@include mq("tablet-small", "min") {
			padding-top: calc(55px + 0px);
		}	

	}

	.page-body {
		font-family: $bold-font-face; 
		font-size: 13px;
		display: flex;
		flex-wrap: wrap;

		@include mq("tablet-small", "max") {	
			margin-bottom: 12px;
		}

		.products-container {
			flex-grow: 1;
			flex-basis: calc(100% - 260px);
			order: 4;
			margin-right: -12px;
			overflow-x: hidden;

			@include mq('tablet', 'max') {
				margin-right: 0;
				// flex-basis: 100%;
    			margin: 12px -12px 0;
			}

			@include mq("phone-wide", "max") {	
				margin: 0px -6px;
				padding: 6px 12px 0px 12px;
			}
		}

		.grid-container {
    		display: flex;
    		flex-flow: row nowrap;
		}

		@include mq('tablet', 'max') {
			flex-direction: row-reverse;
		}
		@include mq("phone-wide", "max") {
		    padding: 12px;
		    margin-top: 0;
		}
	}

	.products-header {
    	flex: 3 75%;
    	order: 1;
    	height: 41px;
    	padding-bottom: 5px;

    	justify-content: flex-end;
    	
    	@include mq('tablet', 'max') {
			align-self: center;
			order: -1;
	    	flex: 2 2 47%;
	    	display: flex;
	    	justify-content: flex-end;
		}	

    	@include mq('tablet', 'min') {
    		display: flex;
			flex-flow: row;
			flex-wrap: nowrap;
			flex: 4 4 100%;
    		margin-bottom: 25px;
    	}

    	.title {
    		flex: 1 1 50%;
			font-size: 30px;
			height: 62px;
			font-weight: 500;
			color: $brand-color; 
			// font-family: $standard-font-face;

			// display: none;

			@include mq('tablet', 'min') {
				line-height: 62px;
    			// margin-left: 240px;
			}
    	}

	}

	.product-list {
	    &:after {
	        content: "";
	        display: block;
	        // min-width: 450px;

		    @include mq('tablet', 'max') {
		        width: calc(50% - 24px);
		        flex-basis: calc(50% - 24px);
		    }

	    position: relative;
	    box-sizing: border-box;
	    overflow: hidden;
	    display: flex;
	    flex-flow: column nowrap;
	    justify-content: space-around;
	    flex-basis: 270px;
	    flex: 1 0 270px;
	    margin: 0px -1px -1px 0px;
	    padding: 20px;
	    max-width: 240px;
	    /*height: 285px;*/

  		@include mq('tablet-small', 'min') {
  			// max-width: 480px;
  		}

	    }
	}


}

.sort-by-container{
	/*	justify-content: flex-end;
	    display: flex;*/
	/*    width: 95vw;*/
    // position: relative;
    /*right: 60px;*/

    right: 0px;

	@include mq('desktop', 'min') {
		right: 60px;
	}

	@include mq('desktop-wide', 'min') {
		right: 0px;
	}

}