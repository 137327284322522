.home-spotlight-category-main-div {
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  max-width: 948px;
  width:95%;
  margin: 0 auto;
  position: relative;
  bottom: 24px;
  border-radius: 5px !important;
  overflow: hidden;
  justify-content: space-between;

  a{
    border-radius: 3px;
  }

}

.home-spotlight-category{
  text-align: center;
  width:91vw;
  height: 288px;
  text-decoration: none;
  border: 1px solid #DFDFDF;

  &:hover{
    text-decoration: none;
  }


  @include mq("phone-smallest", "min") {
      height: 140px !important;
      margin-bottom: 11px !important;

      margin-left: 7px;
  }

  @include mq("phone-small", "min") {
      /*height: 176px !important;*/
      width:86vw;
  }

  @include mq("phone", "min") {
      /*width: 370px !important;*/
      width:89vw;
  }

  @include mq("phone-mid", "min") {
      /*width: 390px !important;*/
  }  

  @include mq("phone-wide", "min") {
      height: 166px !important;
      /*width: 440px !important;*/      
      width: 88vw !important;
  }

  @include mq("600-pixles", "min") {
      /*height: 150px;*/
      /*width: 555px !important;*/
      width: 92vw !important;
      margin-left: 0px;
  }  

  @include mq("tablet-small", "min") {
      /*width: 236px !important;    */  
      width: 30vw !important;    
      margin-bottom: 11px;
      /*height: 299px !important;*/
      height: 289px !important;
  } 

  @include mq("desktop", "min") {
      width: 300px !important;      
  }   

  div{
    width: 100%;
    height: 100%;
    display:flex;
    justify-content: center;
    align-items: flex-end;
    background-size: contain;



    span {
      font-size: 30px;
      color: #3D3D3D;
      /*text-shadow: 0px 0px 8px rgba(0, 0, 0, 1.0);*/
      /*padding-bottom: 10px;*/
      text-decoration: none;
      background-color: #fff;
      width: 100vw;
      height: 67px;
      display: flex;
      justify-content: center;
      align-items: center;

      position: relative;
      bottom: -65px;

      @include mq("phone-smallest", "min") {
        padding:0px !important;
        bottom: -42px;
        height: 42px;
        font-size: 18px;

      }

      @include mq("phone-smallest", "min") {
        font-size: 18px;
      }

      @include mq("phone-wide", "min") {
        font-size: 24px;
        padding: 0px 15px 10px 15px;
      }      

      @include mq("phone-wide", "min") {
        bottom: -68px;
        height: 68px;
      }          

      @include mq("tablet-small", "min") {
        bottom: -66px;
        height: 66px;
      }          

    }
  }
}

.home-spotlight-category:nth-child(odd){
  /*background-color: #185283;*/
}

.home-spotlight-category:nth-child(even){
  /*background-color: #0B2B52;*/
}

@include mq("tablet", "max") {
  .home-spotlight-category{
    /*width: 50%;*/
}

  .home-spotlight-category:nth-child(3), .home-spotlight-category:nth-child(4){
    /*background-color: #185283;*/
  }

  .home-spotlight-category:nth-child(2), .home-spotlight-category:nth-child(5), .home-spotlight-category:nth-child(6){
    /*background-color: #0B2B52;*/
  }

  .home-spotlight-category:nth-child(7){
    /*background-color: #185283;*/
  }
}


.home-spotlight-category-title{
	position: relative;
	bottom: 15px;
}


.spotImage1{
   background: url(../images/spot-items/mobile-spot-cat-img1.jpg) center center / contain no-repeat !important;

   @include mq("tablet-small", "min") {
      background: url(../images/spot-items/spot-items1.png) center center / contain no-repeat !important;    
   }

}

.spotImage2{
  background: url(../images/spot-items/mobile-spot-cat-img2.jpg) center center / contain no-repeat !important;

  @include mq("phone-wide", "min") {
      background-position-y: 0px !important;
  }  

  @include mq("600-pixles", "min") {
    background-position-y: -15px !important;
  }

  @include mq("tablet-small", "min") {
      background: url(../images/spot-items/spot-items2.png) center center / contain no-repeat !important;    
   }  

}

.spotImage3{
  background: url(../images/spot-items/mobile-spot-cat-img3.jpg) center center / contain no-repeat !important; 

  @include mq("600-pixles", "min") {
      background-position-y: -25px !important;
  }   

  @include mq("tablet-small", "min") {
      background: url(../images/spot-items/spot-items3.png) center center / contain no-repeat !important;    
   }   
  
}

.spotSize{

   @include mq("phone-smallest", "min") {
      max-height: 97px !important;      
      background-size: 320px 107px !important;
   }

   @include mq("phone-small", "min") {
      background-size: 340px 107px !important;
   }

   @include mq("phone", "min") {
      background-size: 418px 107px !important;
      width: 100% !important;
   }

   @include mq("phone-wide", "min") {
      /*background-size: 451px 107px !important;*/
      /*background-size: 513px 107px !important;*/
      background-size: 100% !important;
   }

   @include mq("600-pixles", "min") {
      /*background-size: 561px 107px !important;*/
      /*background-size: 691px 107px !important;*/
      /*background-size: 100% !important;*/
      width: 100% !important;
   }     

    @include mq("tablet-small", "min") {
      /*width: 236px !important;*/
      width: 100% !important;
      background-size: 300px 223px !important;
      max-height: 223px !important;
   }       

   @include mq("desktop", "min") {
      background-size: 300px 227px !important;
      width: 100% !important;
   }    

} 