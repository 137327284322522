/*
 * Flex UX Framework
 *
 * Filename: checkout-addresses-order-type.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */



	.checkout-side-top--addresses {
		.checkout-small-text {
    		padding-bottom: 10px;
    		font-size: 13px;
    		line-height: 1.25em;
			padding-top: 3px;
			padding-bottom: 3px;
		}

		.mdl-radio {
			display: inline-block;
			width: 100%;

			span{
				font-size: 14px;
			}
		}
	}
