/*
 * Flex UX Framework
 *
 * Filename: myaccount-vieworders.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.my-account {
	.order-cards-grid {
		@include mq("tablet", "min"){
	    	display: none;
		}

		a{
			text-decoration: none;
		}

		.orders-card{
			padding:15px;
		    width: auto;
		    min-height: 135px;

		    .mdl-card__title{
		    	align-self: flex-start;
		    	word-break: break-all;
		    	font-size: 14px;
		    	font-weight: 700;
		    	color: #737677;
		    	text-align: left;
		    	padding-bottom: 0;
		    }

		    .mdl-card__supporting-text{
		    	color:#333;
		    	font-size: 13px;
		    	line-height: 18px;

		    }
		}
	} 

	.p-table{

		thead{
			font-family: $bold-font-face;
			font-weight: 500!important;
			color: $primary-font-color;
		}
		@include mq("tablet", "max"){
		    display: none;
		}

		@include mq("tablet", "min"){
    	    margin: 0 auto;
		    max-width: 960px;
		    font-size: 14px;
		    line-height: 28px;
	    }
	    
	    td{
	    	@include mq("tablet", "min"){
				text-align: center;
			}
	    }

	    th {
	    	font-weight: 500!important;
	    }
	}
}

.page-body .p-table td > span > div.price{
	font-weight: normal;
}
