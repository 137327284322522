/*
 * Flex UX Framework
 *
 * Filename: myaccount_link.scss
 * Type:     Component Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

.my-account{
    .mdl-tabs {
        &__tab {
            padding: 0px 15px;
            transition: background 0.15s;
            // font-family: $standard-font-face;
            border: 1px solid #E5E5E5; 
            height: 45px;
            height: 48px;
            font-size: 14px;
            font-family: $bold-font-face;
            font-weight: 500;
            border: 0;
            color: $primary-font-color!important; 
            text-transform: capitalize; 

             &:hover {
                background: #F9F9F8; 
             }
     

           @include mq("phone-wide", "max"){
                padding: 0 12px;
            } 

            &-bar {
                border-bottom: 1px solid #E5E5E5; 

                .mdl-tabs__tab {
                    &.is-active{
                        &:after{
                           background: $brand-color;
                            height: 3px !important;
                        }
                    }
                }
            }
        }

    }
}

