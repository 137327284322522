/*
 * Flex UX Framework
 *
 * Filename: home-spotlight-v1.scss
 * Type:     Partial Stylesheet
 *
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

 .home.v1{
	main{
		.spotlight{
		   .page-header{
			   display: flex;
			   flex-flow: nowrap;
			   flex-direction: column; 

			   height: 450px; 

			   &>div{
				   &:first-of-type{
					   height: inherit;
				   }
			   }
		   }
		}

		.page-body{
			max-width: none;
			padding: 0;
			margin: 0;
			// height: calc(100vh - 670px);
			height: auto;
			display: flex;
			flex-flow: column;

			// @media screen and (max-height: 1180px) {
			// 	height: auto;
			// }

			// @include mq('desktop', 'max'){
			// 	min-height: calc(100vh - 595px);
			// }

			@include mq('tablet', 'max'){
				min-height: calc(100vh - 520px);
			}

			@include mq('tablet-small', 'max'){
				min-height: calc(100vh - 445px);
				height: auto;
			}

			@include mq('phone', 'max'){
				min-height: calc(100vh - 340px);
			}

			   #featured-1{
				   h1 {
					   text-align: center; 
					   //text-transform: uppercase; 
					   margin-bottom: 8px;
					   // font-family: $standard-font-face;
				   }
			   .product-list{ 
				   margin: 0px;
				   height: auto;
				   overflow: visible;
				   .product-card{
					   min-height: 230px;  
					   flex-basis: 20%;
					   min-width: 180px;
					   margin: 0px;
					   border-right: 1px solid #E6E6E6; 
					   border-radius: 0;
					   border-top: 1px solid #E6E6E6;
					   border-bottom: 1px solid #E6E6E6;

					   &:nth-child(n+5), 
					   &:nth-child(n+5) {
						   @include mq('tablet', 'max'){
							   // display: none;
						   }
					   } 

					   @include mq('tablet-small', 'max'){
						   min-width: 50%;
						   }

					   @include mq("desktop-med", "max") { 
						   &:nth-of-type(n + 5) { 
							   // display: none; 
						   }
					   }

					   @include mq("desktop-wide", "min") {   //1374px
						   
						   max-width: none !important; 

					   }
						
					 
					   &:nth-of-type(n + 6) {
						   border-top: none;
						   @include mq("tablet", "min"){
							   // display: none;  
						   }     
					   } 
					   
					   &:nth-of-type(6){
						   border-left: 1px solid #E6E6E6;
					   }

					   &:nth-of-type(9){

						   @media screen and (max-width: 900px){
							   border-left: 1px solid #e6e6e6;
						   }
					   }
					  

					   &:nth-of-type(1) {
						   border-left: 1px solid #E6E6E6;  
					   }

					   @include mq("phone-wide", "max"){
						   min-width: 160px;
					   }

					   .product-image{
						   flex:inherit;
					   }

					   @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
							flex-basis: auto;
					   }
				   }
			   }

			   button{
				   width: 100%;
				   max-width: 125px;
				   align-self: center;
			   }
		   } 

		   .about-us{
			   background: none;
			   margin-bottom: auto;
			   padding-bottom: 15px;
			   
			   div.btn--primary{
				   display: flex;
			   }
		   }	
		}

	}
}