/*
 * Flex UX Framework
 *
 * Filename: login-box.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

 .login {
 	main {
    	background-color: #fff!important;
    	margin: 0 auto;

    	@media only screen and (max-width: 350px) {
    		margin: 0;
    	}
    }
 }

.login-box {
    font-family: $secondary-font-face;

    label {
		text-align: left;
	    color: #737677;
    }

    input {
	    text-align: left;
	    font-family: $secondary-font-face;
	    font-size: 14px;
	    color: $primary-font-color;	 

	}

	a {
	   font-size: 14px;
	   
	   &.register, #cancel-btn{
			text-decoration: none;
		}
	}

	.mdl-grid {
	    justify-content: center;
	    text-align: center;
	    align-items: center;
	    
	    .mdl-cell{
			// display: flex;
			justify-content: center;

			.form-group	{
				padding-bottom: 11px!important;
			}
	    }
	}

	.mdl-textfield {
	    //color: #737677;
 
		&.is-dirty {
		    //color: #333;
		}
		
		&__input {
		    padding-left: 3px;
		    font-family: $secondary-font-face;
		    font-size: 14px;
		}

		&.is-focused,
		&.is-dirty {
			.mdl-textfield__label{
				color:$brand-color-secondary !important;

			}
		}

		.mdl-textfield__label:after {
	    	background-color: $brand-color !important;
		}
	}

	.material-icons {
	    font-size: 12px;
	}

	.login-button {
		display: inline;
	    font-family: $bold-font-face;
	    font-size: 16px;
	    width: 300px;

	    @media only screen and (max-width: 350px) {
			width: 264px;
		}
	}

	#register-btn {
		display: inline;
		width: 300px;
		font-family: $bold-font-face;
		font-size: 16px;
		// letter-spacing: 1px;

		@media only screen and (max-width: 350px) {
			width: 264px;
		}
	}

	#captcha {
		padding-bottom: 30px!important;

		@media only screen and (max-width: 350px) {
			transform: scale(0.88);
		}
	}
}

.registerBody{
	a{
		text-decoration: none;
	}
}