/*
 * Flex UX Framework
 *
 * Filename: checkout_completed.scss
 * Type:     Template Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.checkout-completed {
    .page-body {
        max-width: 600px !important;
     //   margin: 9px !important;    

        .checkout-container {
            margin: 10px 20px 10px 5px;  
        }

        h1 { 
           margin-left: 5px !important; 
           margin-bottom: 0px; 
        }

        p{
            font-size: 14px;
            line-height: 1.5;
        }
    }
}