/*
 * Flex UX Framework
 *
 * Filename: checkout-addresses-controls.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.checkout-address-controls {
    display: flex;
    padding: 16px;
    justify-content: flex-end;

    @include mq("tablet", "max"){
	    flex-flow: column;
	    align-items: center; 
	}

	button{
		min-height: 45px;
		height: auto;
		line-height: 20px;
		padding: 3px 25px; 
		 @include mq("tablet", "max"){
		    min-width: 279px;  
		}
	}

	.mdl-button:not(:last-child) {
	    @include mq("tablet", "min"){
	    	margin-right: 20px;
		}
	}
}


