/*
 * Flex UX Framework
 *
 * Filename: basket-controls.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.checkout-basket {
	.price {
		color: $brand-color;
	}
}