/*
 * Flex UX Framework
 *
 * Filename: header.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


// NOTE: This file makes exceptions to the standard skinning process by determining its
//       own position. This is because the header is in a fixed position at all times.

header {
	position: relative;
	width: 100%;
	background: #fff;
	z-index: 2;
	height: $header-height;
	border-bottom: 1px solid #E5E5E5; 
	// margin-bottom: 155px;

	@include mq('tablet', 'max') {
		height: 50px;
		position: fixed;
	} 

	.site-header {
		height: $header-top-height;
		background: $header-top-background;
		// font-weight: normal;
		font-family: $bold-font-face;
		font-size: 10px;
		color: $primary-font-color; 
		display: flex;
		align-items: center;
		font-size: 15px;
 
		@include mq('tablet', 'max') {
			display: none;
		}

		div {
			display: flex;
		}

		&__welcome {
			padding: 7px 0;
			padding-left: 158px; 
			font-size: 15px; 
			text-transform: none;
			color: $header-top-font-color; 
			height: 100%;
			line-height: 61px; 
		}

		&__nav-menu {
			padding-left: 20px;
			margin: 0;
			margin-right: 10px;
			height: 100%; 

			li {
				display: inline-block;
				list-style-type: none;
				padding-left: 13px;
				padding-right: 13px;
				height: 100%; 
				// line-height: 35px;
				cursor: pointer;  
				font-size: 15px;   

				&:hover {
					// background: $header-main-nav-background-hover;
					a {
						text-decoration: underline;
					}
				}

				a {
					color: $header-top-font-color;
					text-decoration: none;
					text-transform: capitalize;
					// font-weight: normal;
					display: inline-block;
					height: 100%;
					// line-height: 40px;
					line-height: 61px; 
					font-family: $bold-font-face; 
					font-size: 15px;     
				}

			}

		}

		.site-header__left {
			 
			max-height: $header-top-height;
			height: 100%; 
			display: flex;
            align-items: center; 
            margin-right: auto; 
            margin-left: 30px; 

			.currency-dropdown {
                height: 100%;
                display: flex; 
                height: 30px; 
                width: 124px;    
				select {
				    background: #FFF; 
			    } 

			}

			

			@media only screen and (min-width: #{$body-max-width}) {
				left: calc((100vw - #{$body-max-width}) / 2);
			}

			.mdl-textfield {
				padding: 0;
			}
		}

	}

	.main-nav {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: space-between;
		// font-weight: 700;
		padding-left: 0px;
		padding-right: 20px;
		height: $header-main-height;
		//color: #333;
		font-family: $standard-font-face;
		background: $header-background;
		 

		@include mq('tablet', 'max') {
			height: $header-tablet-height;
			padding-right: 49px;
		}

		@include mq('phone-wide', 'max') {
			height: $header-phone-height;
			padding-right: 49px;
		}

		&__left, &__right {
			display: flex;
			align-items: center;
			justify-content: space-between;
			height: 100%; 
		}

	}

	.main-nav__right {
		flex-flow: row nowrap;
// 		margin-top: 13px; 

		@include mq('tablet', 'max') {
			margin-bottom: 0;
			margin-top: 0;
		}

		.mdl-button--search {
			top: 0;
			bottom: 0;

			&:hover {
			    background-color: $brand-color-secondary;
			}

			.material-icons {
				font-size: 27px;
				height: 27px;
				width: 27px;
			}

		}

		.mdl-textfield--expandable {
			padding: 0;
			// width: 215px;
		}

		#searchField {
			// width: 170px;
			// border-bottom: none;
		}

		.search-desktop {
			display: flex;
			margin: 0;
			align-items: center;
		} 

		.search-textfield-container {
			position: relative;

			.mdl-textfield {

				&__label::after {
					background-color: $header-search-underline-color !important;
					left: 0;
					visibility: visible;
					width: 100%;
				}

				&__expandable-holder {
					// margin-left: 30px;
					// min-width: .01px;
					// max-width: auto;
				}
			}

			.mdl-textfield__input {
				color: $header-search-font-color;
				// font-family: $secondary-font-face;
			 //    padding-left: 0px !important; 
				// height: 25px;
				// border-bottom: 1px solid $header-search-underline-color;
			}
 
			@include mq('tablet', 'max') {
				display: none;
			}

		}

		.material-icons {
			margin-right: 1px;
			color: $header-icons-color;
			transform: translate(-14px, -11px); 
			 
		}

		.cart {
			margin-left: 8px;

			.mdl-button{
				&:hover {
				    background-color: $brand-color-secondary;
				}
			}

			.cart-container {
				width: 34px;
				height: 34px;

				.material-icons::after {
 					border: 1px solid $header-minicart-badge-border; 
					width: 12px;
					height: 12px; 
					top: -3px;
					right: -1px;
					font-size: 8px;
					background: $header-minicart-badge-color;
					color: $primary-font-color; 
					font-family: $bold-font-face;   
				}

			}

			.mdl-menu {
				&__container {
					top: 44px;
					max-height: calc(100vh - 108px);
					margin-top: 8px;

					@include mq('tablet', 'min') {
						height: 295px !important;
					}

					.shopping-cart {
						padding: 0;
						width: 430px;
						max-width: 100vw;
						display: flex;
						flex-flow: column nowrap;
						color: $brand-color;
						font-weight: 500;

						.cart-header {
							padding: 0 20px;
							border-bottom: 1px solid rgba(226, 226, 226, 0.5);
							height: 80px;
							display: flex;
							align-items: center;
							box-sizing: border-box;

							@include mq('tablet', 'max') {
								height: 50px;
								min-height: 50px;
							}

							.cart-title {
								font-size: 23px;
								// text-transform: uppercase;
								line-height: 1.75;
								color: $primary-font-color;
								font-family: $bold-font-face;
								font-weight: 500;
	

								@include mq('tablet', 'max') {
									font-size: 19px;
								}

							}

						}

						.cart-item-container {
							overflow-y: auto;
							flex-grow: 1;
							padding: 0 20px;
							max-height: 472px;

							.cart-item {
								display: flex;
								align-items: center;
								height: 135px;
								box-sizing: border-box;
								position: relative;
								font-family: $bold-font-face; 

								.cart-item-img {
									display: flex; 
									width: 85px;

									img {
										margin: auto;
										max-height: 85px;
										max-width: 85px;
									}

								}

								.cart-item-text {
									flex: 1;
									display: flex;
									flex-wrap: wrap;
									margin-left: 30px;

									.cart-item-name {
										text-transform: uppercase;
										flex: 2 100%;
										margin-bottom: 25px;

										a {
											// font-weight: 700;
											font-size: 13px;
											line-height: 16px;
											text-decoration: none;
											color: $primary-font-color;
										    font-family: $bold-font-face; 
										}

									}

									.cart-item-quantity {
										flex: 1 50%;
										// font-weight: 400;
										color: $primary-font-color;
									}

									.cart-item-price {
										flex: 1 50%;
										color: $brand-color;
									}

								}

							}

						}

						.cart-footer {
							border-top: 1px solid rgba(226, 226, 226, 0.5);
							display: flex;
							justify-content: space-between;
							align-items: center;
							padding: 0 20px;
							height: 80px;

							@include mq('tablet', 'max') {
								height: 59px;
								min-height: 59px;
							} 

							.cart-subtotal {
								font-size: 16px;  
								font-family: $bold-font-face; 
								font-weight: 500;
								color: $brand-color; 
								line-height: 1.15em; 

								@include mq('tablet', 'max') {
									font-size: 15px;
								}

							} 

							.checkout-btn:hover {
								background-color: $brand-color-btn-primary-hover;
							    text-decoration: none; 
							}

							.mdl-button--colored {
								font-size: 16px; 
								background: $brand-color-btn-primary; 
								color: #fff; 
							}

						}

					}

				}

			}

		}

	}

	.main-nav__left {
		// flex-flow: row wrap;
		padding: $header-logo-padding;

		@include mq('tablet', 'max') {
			padding: 0;
		}

		@include mq('desktop', 'max'){
// 			flex-flow: row wrap; 
		} 

		.desktop-nav {
			@include mq('tablet', 'max') {
				display: none !important;
			}

			display: flex;
			flex-flow: row wrap;
			height: 100%;  
            align-items: center; 

			@include mq('desktop', 'max'){
				height: 125px;
			}

			& > ul {
				display: flex;
				padding-inline-start: 0px; 
				padding-left: 0;
				margin-top: 0;
				height: 50px;  
				margin-bottom: 0px; 
				top: 79px; 
			}

			& > ul > li {
				padding-right: 4px;
				position: relative;
				display: inline-block;
				list-style: none;
			}

			& > ul > li[data-subnav] {
				position: relative;
				display: inline-block;
				list-style: none;
				// width: 186px;

				.nav-handle {
					position: relative;
					margin-left: 0;
					min-width: 200px;
					max-width: 200px;
					
					a span {
						margin-right: 18px;  
					}
				}

				> .nav-handle {
					
					&:after {
						font-family: 'Material Icons';
						content: '\e5cb';
						display: inline-block;
						transform: rotate(-90deg);
						font-size: 24px;
						color: #fff; 
						position: absolute;
						right: 18px;
						top: 5px;
						clip: rect(6px, 22px, 32px, 0px);
					}

				}

				.sub-nav {
					z-index: 10;
					padding: 0;
					display: none;
					opacity: 0;
					padding-bottom: 6px;
					position: absolute;
					background-color: $brand-color;

					&-item {
						position: relative;
						margin: 0;
						cursor: pointer;
						display: block;
						white-space: normal;

						a { 
							color: #fff;
							font-family: $bold-font-face;
							text-align: left;
							// font-weight: 400;
							padding-left: 26px;
							white-space: inherit;
							padding-top: 5px;
							padding-bottom: 5px;
						}

						&:hover {
							background-color: $brand-color-secondary; 

							a{
								color: #fff;
								background-color: $brand-color-secondary; 
								text-decoration: none; 
							}
						}
 
						// a:hover {
						// 	color: #fff;
						// 	background-color: $brand-color-secondary; 
						// 	text-decoration: none; 
						// }
					}

					.nav-handle {
						padding: 0;
						min-height: 32px;
						height: auto;
						line-height: 1.5em;
						white-space: normal;

						&:hover {
							background-color: $brand-color-secondary; 
						}
					}

				}

				.sub-nav [data-subnav] {
					& > .nav-handle {
						padding-right: 45px;
						width: calc(100% - 69px);

						&::after {
							font-family: 'Material Icons';
							content: '\e5cc';
							display: inline-block;
							font-size: 22px;
							color: $brand-color;
							position: absolute;
							right: 10px;
							top: -2px; 
							clip: rect(6px, 22px, 32px, 0px);
							text-decoration: none!important;

							&:hover {
								text-decoration: none!important;
							}
						}

						&:hover {
							background-color: #F2F2F2;
						}

						a:hover {
							color: #000; 
						}

					}
				}
			}

			& > ul > li {

				&:nth-child(4) {
					.nav-handle {
						min-width: 60px;     
					}
				} 
 
				.nav-handle {
					border-radius: 0;
					padding: 0 4px;  
					display: flex;
					flex-flow: column;
					justify-content: center;
					margin-top: 6px;
					height: 45px;
					// height: auto;

					&:hover {
						background-color: $brand-color-secondary; 
					    a {
					    	color: #fff;

					    }
					} 

				}

				a {
					color: #fff;
					text-transform: none;
					text-decoration: none;
					font-family: $bold-font-face;
					font-size: 14px;     
				}

				.nav-handle-line-1 {
					max-width: 186px;
					padding-right: 0;

					a {
						font-size: 15px; 
						// font-weight: normal;
						line-height: 1;
						margin-left: 10px; 
						margin-right: 10px; 
					}
				}

				.nav-handle-line-1-1 {
					padding-left: 0;
					margin-top: 6px;
					height: 45px;
				}

				.nav-handle-line-1-3 {

					padding-right: 0px; 

				}

				.nav-handle-line-1-4 {
                    
				}

			}

		}
 
		.logo {
			max-height: $header-logo-height;
			height: $header-logo-height; 
			width: $header-logo-width; 
			position: absolute;
			top: 23px; 

			@include mq('tablet', 'max') {
				max-height: 24px;
				width: auto;
				bottom: unset;
				margin: 5px 15px;
				top: 8px;
			}
		}
	}
}

.mobile-drawer-nav {
	position: fixed;
	// position: relative;
	z-index: 100;
	pointer-events: none;
	font-family: $standard-font-face;

	.drawer-logo-container {
		background: $drawer-mobile-background;
		margin-top: 0;
		height: 50px; 
		padding: 0px;
		display: flex;
		align-content: center;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid #E5E5E5; 

		.mobile-close {
			color: red;
			background: red;
			padding: 10px; 
			//display: none; 
		}

		.close-menu{
			position: absolute;
			right: 20px;
			top: 12px;
			color: #fff !important;
			cursor: pointer;
			// &:hover{
			// 	color: $link-color-hover !important;
			// }
		}

		img {
			padding: 25px;

			@include mq('tablet', 'max') { 
			    height: 24px; 
				width: auto;
				padding: 0;
				margin-left: 15px;  
				max-width: 228px; 
				top: 8px;
			}		 	
		}
	}

	.mobile-nav-page-controller {
		margin-bottom: 10px;
		position: relative;
		overflow: hidden;
		-webkit-transition: height 0.3s;
	}

	.mobile-nav-page {
		position: absolute;
		width: 100%;
		-webkit-transition: margin 0.3s;
	}

	.mdl-layout__drawer {
		&.is-visible {
			transform: translateX(0);
			pointer-events: all;
		}

		background: #fff;
		// font-weight: bold;
		border: none;
		text-transform: uppercase;
		width: 280px;
		transform: translateX(-280px);

		&-button {
			color: $header-icons-color;
			top: -3px;
			left: auto;
			right: -4px;
			pointer-events: all;
		}

	}

	.mdl-navigation {
		padding-top: 0;
		font-family: $bold-font-face; 
		font-weight: 500;
		text-transform: Capitalize;

		ul {
			font-size: 14px; 
			padding-left: 20px;
		}

	}

	ul {
		.mobile-nav-handle {
			a {
				color: $primary-font-color;
				font-weight: 500; 
				line-height: 1.75; 
			}

		}

		[data-subnav] {
			i.material-icons {
				color: $brand-color-secondary;
			}

		}

		li {
			display: flex;
			margin-bottom: 10px;
		}

	}

	ul.top-buttons {
		li.top-btn {
			a {
				color: $brand-color;
				font-weight: 500; 
			}

		}

	}

	.back-button {
		cursor: pointer;
		padding-left: 20px;
		margin-bottom: 8px;
		display: flex;
		line-height: 24px;
		color: $primary-font-color;
		font-size: 14px;  

		i.material-icons {
			color: $brand-color;
			margin-right: 10px;
		}

	}

	.sub-nav-title {
		// font-weight: bold;
		color: $primary-font-color; 
		line-height: 1.75; 

		&:before {
			content: "•";
			font-size: 23px;
			line-height: 25px;
			color: $brand-color;
			margin-right: 6px;
		}

	}

	.page-right {
		margin-left: 100%;
	}

	.page-left {
		margin-left: -100%;
	}

	.search-textfield-container {
		padding: 0 20px;
		padding-right: 15px;
 
		.mdl-textfield {
			display: flex;
			padding: 27px 0;
			width: 100%;
			margin-left: -11px;
			padding-top: 14px !important;
		}

		.mdl-textfield__input {
			position: relative;
			top: 6px;
			text-align: left;
		}

		input {
			width: calc(100% - 40px);
			margin-left: 40px;
			border-bottom: 1px solid lightgray;
			border-radius: 0;
		}

		.mdl-textfield__label {
			width: calc(100% - 40px);
			margin-left: 40px;
		}

		.mdl-textfield.is-dirty, .mdl-textfield.is-focused {
			.mdl-textfield__label {
				color: $brand-color !important;
			}

		}

		i {
			color: $brand-color;
		} 

	}

	// .search-textfield-container
}

//.mobile-drawer-nav

nav ul li a {
	text-decoration: none;
}

.page-header {
	background: #fff;
	display: block;

	@include mq("tablet", "max") {
		flex-flow: column-reverse nowrap;
	}
}

// .page-header, .page-header .category-header-img {
// 	@include mq('phone', 'max') {
// 		height: 100px;
// 	}
// }
