/*
 * Flex UX Framework
 *
 * Filename: faqs-renderer.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.faqs {

	.page-body {

		.mdl-accordion {
			margin-top: 12px;
			border-bottom: none;
  			.mdl-accordion__icon {
    			margin-left: auto;
			}
		}
	}
 
	.mdl-accordion__button {
    	 
    	font-size: 14px;
    	margin-left: 5px;
    	margin-top: 5px;
    	cursor: pointer;
        line-height: 1.15em;
        color: $primary-font-color;
        opacity: initial;
        font-family: $bold-font-face;
        font-weight: 500;
	}

	.mdl-collapse__content {
    	padding: 12px;
    	font-size: 14px;
    	line-height: 21px;
    	bottom: 11px;
    	font-family: $standard-font-face;

    	p {
            line-height: 1.5em;
    		font-size: 14px;
		}
	}	
}