/*
 * Flex UX Framework
 *
 * Filename: breadcrumbs.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.breadcrumb {
    height: 39px;
    line-height: 42px;
    background: #fff;
    font-size: 13px;
    // font-weight: 400;
    font-family: $bold-font-face; 
    overflow: hidden;
    position: relative;
    padding-left: 27px;  
    border-bottom: 1px solid #E6E6E6; 
    white-space: normal; 
    text-overflow: clip;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;

    a , span{
        margin-right: 8px;
        color: $primary-font-color;
        text-decoration: none;
    }

    @include mq("tablet", "max"){
        padding: 0 15px;
    }


}

