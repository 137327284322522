/*
 * Flex UX Framework
 *
 * Filename: register.scss
 * Type:     Template Stylesheet
 *
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

.register{
    .mdl-textfield{
        label{
            text-align: center;
            color: #737677;
        }
    }
}