/*
 * Flex UX Framework
 *
 * Filename: home-spotlight-v1.scss
 * Type:     Partial Stylesheet
 *
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

 .home { 

 	main {
 		background: #FFF; 
 	}
 	
 	.page-body { 
 		margin:  24px auto auto;
 		padding: 12px;
 		background: inherit;

 		@include mq("phone-wide", "max") {
		    padding: 6px;
		    margin-top: 0;
		}
 	}
 }

.spotlight {
	.page-header {
		position: relative;
		padding-top: $header-height;
		@include mq("tablet", "max") {
    		padding-top: 0px !important;  
		}
	}
	
	.text-wrap {
		max-width: 1069px;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 50px;


		@include mq("phone-wide", "max") {
			padding: 25px;
		}

		h2 {  
			font-size: calc( 18px + 4vw); 
			margin-bottom: 0px;
			font-family: $standard-font-face;
			color: #fff;
			text-shadow: 3px 3px 6px black;
			text-align: center;

			@include mq("desktop-wide", "min") {
				font-size: 72px; 
			}

			@include mq("tablet-small", "max") {
				margin-bottom: 0px; 
			}
		}

		h4 {
			font-family: $standard-font-face;
			color: #fff;
			text-shadow: 3px 3px 6px black;
			text-align: center;
			font-size: calc( 14px + .75vw); 
			margin-bottom: 37px;
			line-height: 1.5;

			@include mq("desktop-wide", "min") {
				font-size: 24px; 
			}

			@include mq("tablet-small", "max") {

				margin: 14px 0px 20px 0px;
			}
		}
	}
}
