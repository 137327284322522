/*
 * Flex UX Framework
 *
 * Filename: contact_us.scss
 * Type:     Template Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.contact-us {

	main {
		// background: #fff;
	}

    .page-header {
        height: 0;
    }

	.page-body {
    	max-width: 600px;
    	margin: auto;
    	margin-top: auto;
    	padding: 12px 12px 50px 12px;
   		font-size: 14px;
    	line-height: 1.5em;
    	font-family: $standard-font-face;
        .companyInfo{
            p:first-of-type{
                padding-bottom: 20px;
            }
        }
        h1{
            margin-top: 30px;
            margin-bottom: 12px;
            font-family: $bold-font-face;
        }
        p{
            font-family: $secondary-font-face;
        }
        strong{
            font-family: $standard-font-face;
        }
	}
}