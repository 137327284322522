/*
 * Flex UX Framework
 *
 * Filename: item-header.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.product {

	&-details-header, &-title {
		font-size: 20px;
		line-height: 1.15;
		height: auto; 
		font-family: $secondary-font-face; 

	}

	&-title {

      font-family: $bold-font-face;  
	}


	&-details-header {
	  	border-bottom: 1px solid rgba(226,226,226,0.5);
		padding-bottom: 5px;

		.product-title, .product-price{
			@include mq("tablet", "max"){
				line-height: 23px;
			}
		}

		.product-sku{
			@include mq("tablet", "max"){
				padding-bottom: 10px;
			}
		}

		.product-moq {
			font-family: $bold-font-face;
			font-weight: 500!important;
		}
	}

	&-sku { 
		font-weight: 400;
		font-size: 14px;
		padding: 10px 0px;
		color: $primary-font-color;
		text-transform: uppercase;
		line-height: 1;
		font-family: $secondary-font-face;  
	}

	&-price {
		font-weight: 500;
		font-size: 20px;
		line-height: 23px;
		display: inline-block;
		text-transform: uppercase;
		width: 48%;
		font-family: $standard-font-face;    

		.price { 
			font-size: 20px;
			color: $brand-color;
			font-family: $bold-font-face;   
		}
	}

	&-size-chart-toggle {
    	text-align: right;
    	display: inline-block;
    	width: 49%;
    	font-size: 14px;
    	line-height: 1;
	}
}
