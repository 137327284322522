/*
 * Flex UX Framework
 *
 * Filename: basket.scss
 * Type:     Template Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

.checkout {

    h1, h2 {
        margin-top: 0;
        // font-family: $standard-font-face;
    }

    

    .page-body {
        max-width: 1175px;
        margin: 24px auto auto;
        padding: 12px;
        height: 100vh;

        @include mq("tablet", "max"){
            padding: 15px 5px 5px 5px;
            margin-bottom: 50px;
            height: auto;
        }
    }

    .page-header {
        height: 0;
    }
}

.checkout-basket {

   .checkout-container {
       display: flex;
       flex-wrap: wrap;

       @include mq('tablet', 'max') {
            margin: 10px;
       }

       @include mq('tablet', 'min') {
            margin: 20px;
       }

       .checkout-main {
            @include mq('tablet', 'max') {
                flex: 1 1 100%;
            }

            @include mq('tablet', 'min') {
                flex: 75 1;
                margin-right: 20px;
            }

            display: flex;
            flex-flow: column;

            .checkout-main-top {
                margin-bottom: 20px;
                background-color: #fff;

                .checkout-item {
                    display: flex;
                    white-space: nowrap;
                    border-bottom: 1px solid #efeff1;

                    .checkout-item-main {
                        display: flex;
                        align-items: center;
                        flex: 8 8 80%;
                        font-size: 12px;
                        line-height: 1.15em;

                        @include mq('phone-wide', 'min') {
                            padding: 25px 20px 25px 25px;
                        }

                        .checkout-item-caption {
                            display: flex;
                            align-self: flex-start;
                            flex-direction: column;

                            @include mq('tablet', 'max') {
                                max-width: 60%;
                            }

                            &--title {
                               font-weight: 500;
                               margin-bottom: 3px;
                               font-family: $bold-font-face; 

                               @include mq('tablet', 'max') {
                                    word-wrap: normal;
                                    white-space: pre-line;
                               }
                            }

                            &--code {
                                @include mq('tablet', 'max') {
                                    word-wrap: normal;
                                    //white-space: pre-line;

                                    @include mq("phone-mid", "max") {
                                        white-space: normal;
                                    }
                                }
                            }

                            p.delivery-overorder-message {
                                padding-top: 6px;
                                line-height: 1.15;
                            }

                            @include mq("phone-wide", "max") {
                                p.delivery-overorder-message {
                                    white-space: normal;
                                }
                            }
                        }

                        .delivery-options {
                            display: flex;
                            flex-flow: row;
                            flex-wrap: wrap;
                            // white-space: normal;
                            // padding-inline-start: 0;
                            padding-left: 0;
                            /*margin-top: 6px;*/
                            margin-top: 0px;

                            @include mq("phone-wide", "max") {
                                white-space: normal;
                            }

                            li {
                                flex: 1 1 100%;
                                word-wrap: break-word;
                                height: auto;
                                line-height: 18px;
                                white-space: normal;                                

                                label {

                                    &.mdl-radio__label {
                                        font-size: 12px;
                                        line-height: 1.5em;
                                    }
                                }
                            }
                        }

                        a {
                            text-align: center;
                            // margin-right: 25px;
                            // display: flex;
                            height: 100%;
                            margin-right: 5px;
                        }

                        img {
                            @include mq('tablet', 'max') {
                                max-width: 75px;
                                max-height: 75px;
                                padding-left: 5px;

                                @include mq("phone-wide", "max") {
                                    padding-top: 10px;
                                }
                            }

                            @include mq('tablet', 'min') {
                                max-width: 100px;
                                max-height: 100px;
                            }
                        }
                    }



                    .checkout-item-controls {
                        flex-grow: 2;
                        text-align: right;
                        box-sizing: border-box;

                        @include mq('tablet', 'max') {
                            padding: 10px;
                        }

                    }

                    &--control {
                        padding-top: 3px;
                        padding-bottom: 3px;
                        width: 100%;
                        margin-bottom: 10px;

                        .mdl-textfield {
                            padding: 0;
                        }

                        &__remove {
                            margin-top: 10px;
                            span {
                                text-decoration: none;
                                text-transform: uppercase;
                                font-family: $bold-font-face; 
                            }
                        }
                    }

                    &--quantity {
                        max-width: 35px;
                    }

                    &--update-quantity {
                        cursor: pointer;
                        text-decoration: underline;
                        color:  $link-color;

                        &:hover {
                            color: $link-color-hover;
                        } 

                    }

                    &--remove {
                        cursor: pointer;
                    }

                } // checkout-item
            }
       } // .checkout-main
       .checkout-side {
            
            @include mq('tablet', 'max') {
                flex: 1 1 100%;
            }

             @include mq('tablet', 'min') {
                flex: 25 1;
             }  

            .checkout-side-top {
                flex-flow: column;
                padding: 0;
                background: #fff;

                 @include mq('tablet', 'max') {
                    min-height: 141px;
                 }

                 .checkout-basket-subtotals {
                    flex-flow: row;
                    border-bottom: 1px solid #efeff1;
                    display: flex;
                    flex: 1 1 100%;
                    font-size: 18px;
                       @include mq('tablet', 'max') {
                            max-height: 18px;
                            padding: 10px;
                       }

                       @include mq('tablet', 'min') {
                            padding: 20px;
                       }
                       .checkout-subtotals {
                           &--subtotal-label {
                               flex: 0 4 40%;
                           }
                           &--subtotal {
                              
                               flex: 0 6 60%;
                               text-align: right;
                               font-family: $bold-font-face;  
                           }
                       }
                 }
            }
        }

   }

}

.checkout-basket-promo {
    .mdl-textfield__label {
        color: #737677;
    }
}

.checkout-basket-buttons {
    display: flex;
    text-align: center;
    flex: 1 1 100%;
    font-size: 18px;
    flex-direction: column !important;
    align-items: center;

     @include mq('tablet', 'max') { 
        padding: 10px;
     }
     @include mq('tablet', 'min') { 
        padding: 20px;
     }

     button {
 
            width: 250px;

     }

    .btn--secondary {
        @include mq("phone-wide", "max") {
            width: 100%;
            max-width: 250px; 
       }
    }


     .checkout-basket-taxwarning {
        display: inline-block;
        margin: 15px 20px;
        font-size: 13px;
        line-height: 1.35em;
     }

     .checkout-small-text {
        font-size: 13px;
        padding-bottom: 3px;
        padding-top: 3px;
    }
}    
