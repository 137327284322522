/*
 * Flex UX Framework
 *
 * Filename: _configs.scss
 * Type:     Stylesheet Configs
 *
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


//
// General: Structure
// 

@import url("https://p.typekit.net/p.css?s=1&k=sip1oyw&ht=tk&f=25312.25318&a=646815&app=typekit&e=css");

@font-face {
font-family:"industry";
src:url("https://use.typekit.net/af/08fe52/00000000000000007735a650/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/08fe52/00000000000000007735a650/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/08fe52/00000000000000007735a650/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:400;
}

@font-face {
font-family:"industry medium";
src:url("https://use.typekit.net/af/ddf216/00000000000000007735a656/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff2"),url("https://use.typekit.net/af/ddf216/00000000000000007735a656/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff"),url("https://use.typekit.net/af/ddf216/00000000000000007735a656/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:500;
}


$body-max-width:                    1175px;


//
// General: Color
// 

$brand-color:                       #5E6A72;  
$brand-color-secondary:             #555F67;

$brand-color-btn-primary:           $brand-color;
$brand-color-btn-primary-hover:     #424A50;    
$brand-color-btn-secondary:         #E2E1DD; 
$brand-color-btn-secondary-hover:   #CBCBC7;  

$tertiary-color:					pink; 

$primary-font-color:                #171616; 
$secondary-font-color:				$brand-color-secondary; 
$h1-font-color:                     $brand-color; 
$h2-font-color:                     $brand-color;  
  
$link-color:                        $brand-color;
$link-color-hover:                  #262A2E;   
 
//  
// General: Font 
// 
 
$h1-font-size:                      30px;
$h2-font-size:                      21px;
$standard-font-face:                'industry', sans-serif;
$secondary-font-face:               'industry', sans-serif;    
$bold-font-face:                    'industry medium', sans-serif;  
$drawer-font:                       'industry medium', sans-serif;

// 
// Header
//

$header-background:                	$brand-color;
$header-top-background:				$brand-color;  
$header-height:                     125px; 
$header-top-height:                 61px;
$header-main-height:                64px;  

$header-tablet-height:              50px; 
$header-phone-height:               50px; 
$header-logo-padding:               15px 15px 15px 25px;    

$header-logo-height:               	40px;    
$header-logo-width:               	272px;  

$header-minicart-badge-color:       white; 
$header-minicart-badge-border:      $brand-color; 
$header-top-link-hover-border:      2px solid #000104;
$header-icons-color:                #fff; 
$header-search-font-color:          #fff; 
$header-search-underline-color:     #fff;
 
$header-top-font-color:             #fff; 
$header-main-font-color:            #fff;
$header-main-font-color-hover:      $brand-color;     
$header-main-nav-background-hover:  #38B9E8; 

      
// 
// Mobile Drawer Menu
// 
 
$drawer-mobile-background:          $brand-color;  


//
// Footer
// 

$footer-background:                 $brand-color; 
$footer-link-color:                 $brand-color-secondary;
$footer-link-color-hover:           $primary-font-color; 
