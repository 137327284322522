/*
 * Flex UX Framework
 *
 * Filename: custom_orders.scss
 * Type:     Template Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.custom-orders {

	main {
		background: #fff;
	}

    .page-header {
        height: 0;
    }

	.page-body {

    	max-width: 600px;
    	margin: auto;
        margin-top: auto;
    	padding: 12px 12px 12px 12px;
        padding-bottom: 50px;
    	margin-top: 9px;
    	font-size: 14px;
    	line-height: 1.5em;
    	font-family: $standard-font-face;

        @include mq('phone-wide', 'max'){
            padding: 0px 15px;
            margin-top: 0;
        }
        @include mq("phone-wide", "max"){
            margin-bottom: 40px;
        }
        h1{
            margin-top: 30px;
            margin-bottom: 12px;
        }

        .featured-products-grid {
            .featured-products{

            }.product-card{
                width: 100%;
                max-width: 485px;
                margin: auto;

                a{
                    pointer-events: none;

                    .product-card-image {
                        max-height: 100%;

                        img {
                            max-height: 100%;
                            max-width: 100%;
                        }
                    }
                }

                .price {
                    display: none;
                }
            }
        }
	}
}