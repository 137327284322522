/*
 * Flex UX Framework
 *
 * Filename: home-about-us-v1.scss
 * Type:     Partial Stylesheet
 *
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */
 // @TODO: push margin to parent
 // @TODO: Use global variables for font-family (see _configs.scss)
.about-us {
	background: white;
	border-radius: 5px;
	padding: 50px 30px 0px 30px; 
	max-width: none; 
	text-align: center;
	line-height: 21px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;  
	flex-direction: row;

	@include mq("tablet", "max") {
		flex-direction: column; 
	}

	h1 {
		// font-family: $standard-font-face;
		line-height: 100%;
		font-size: 30px;
		text-transform: capitalize;
	}
	
	p{
		line-height: 21px; 
		font-size: 14px;
		font-family: $secondary-font-face;
		padding-bottom: 5px;

		a{
			ext-decoration: none;
		}
	}

	.copy {
		max-width: 770px;
		margin: 0px;
	}

	.title {
		padding-bottom: 0px;
		margin: 0px;
		margin-bottom: 10px; 
		letter-spacing: 0;
		font-size: 30px;
		text-transform: none;

		@media only screen and (min-width: 640px) {
			line-height: 1.5em;
		}
	}
}
