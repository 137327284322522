/*
 * Flex UX Framework
 *
 * Filename: home-contact-us-v1.scss
 * Type:     Partial Stylesheet
 *
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

 .home-contact-us {
	width: 100%;
	display: flex;
	justify-content: center;
	background: url("../images/contact-bg.jpg");
	background-size: cover;   

    &__content {
		max-width: 948px;
		text-align: center;
		box-sizing: border-box;
		flex: 1 1 100%;
		padding: 24px;
		width: 100%;
		background: white;
		margin: 25px;
		padding: 15px;

		@include mq("phone-wide", "max"){
	    	margin: 25px 12.5px;
	    }

		.contact-us-grid {
			display: flex;
    		justify-content: center;
    		max-width: 700px;
    		padding: 0px;

    		div{
    			margin: 10px;
    		}
    	}

		h1 {
			margin: 10px 0px 0px 0px;  
			font-size: 30px;
			font-family: $standard-font-face;
			text-transform: capitalize; 
		}

    	p {
    		font-size: 14px;
			line-height: 1.5em;
			text-align: center;
			font-family: $secondary-font-face;

			@include mq("tablet", "max") {
				padding-right: 0px;
			}
		}
    }
}