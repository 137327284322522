.home-big-feature {
    display: flex;
    justify-content: center;
    flex-wrap: wrap; 

    a {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        text-decoration: none;
        margin: 24px 14px;
        max-height: 250px;
        width: 459px;

            height: 50vw;

        &:nth-of-type(1) {
            background: url("../images/banner-1-bg.jpg") no-repeat center;
            @media screen and (max-width: 974px) {
                margin: 5px 14px;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
            }
        }

        &:nth-of-type(2) {
            background: url("../images/banner-2-bg.jpg") no-repeat center;
            @media screen and (max-width: 974px) {
                margin: 5px 14px;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
            }
        }

        h1 {
            color: white; 
            text-shadow: 0px 0px 6px #000;
            text-transform: capitalize;
            font-size: 40px; 
            font-family: $standard-font-face;
            margin: 0px 21px;
            @include mq("phone", "max"){
                font-size: 30px; 
            }
        }
    }
}

