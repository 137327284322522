/*
 * Flex UX Framework
 *
 * Filename: basket-empty.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

.checkout-basket-empty {
	text-align: center;

	@include mq("tablet", "max"){
    	margin: 10px;
	}

	// .btn {
 //   		font-family: $bold-font-face;
	// }
	.btn--light {
        font-size: 16px;
        transition: background 0.15s;
        border: 1px solid #F2F2F2;
        height: 45px;
	    font-weight: bold;
	    font-size: 16px;
	    /*letter-spacing: 1px;*/
    }

    p {
    	margin-bottom: 16px;
    }
}
