
.mdl-collapse--opened {
    margin-top: -1px;
}

.mdl-collapse__content-wrapper {
    overflow: hidden;
    /*border: 1px solid $tertiary-color;*/
}

.mdl-collapse__content {
    transition-property: margin-top;
    transition-duration: 0.2s;
}

.mdl-collapse--opened.mdl-collapse__content-wrapper > .mdl-collapse__content,
.mdl-collapse--opened > .mdl-collapse__content-wrapper > .mdl-collapse__content {
    margin-top: 0 !important;
    display: block !important;
}
