/*
 * Flex UX Framework
 *
 * Filename: nav_menu.scss
 * Type:     Component Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

.footer-nav-menu {
	padding: 0px;
	margin: 0px;
	
	@include mq("tablet", "min") {
		height: 100%;
		display: flex;
		align-items: center;
	}	 

	li {

    	@include mq("tablet", "min") {
            height: 100%;
			display: flex;
			align-items: center;
    	} 

		display: inline-flex;
		list-style-type: none;
		font-size: 14px;
		padding-left: 15px;
		padding-right: 15px;
		cursor: pointer;
		font-weight: bold;

		@include mq("tablet", "max") {
			margin: 10px 0px; 
		}
	}
}