/*

 * Flex UX Framework

 *

 * Filename: pagination.scss

 * Type:     Partial Stylesheet

 * 

 * Copyright (C) 2019 Geiger, All Rights Reserved

 */



.pagination{
	height: 80px !important;
	display: flex;

	justify-content:center;

	color: $brand-color-secondary;

	.pagination-left{

		display: flex;
 
	}

	.pagination-numbers{

		display: flex;

		list-style-type: none;

		margin: 0px;

		align-items: flex-start;
		justify-content: center;
		gap: .5rem;

		padding: 0px;

		li{
			display: flex;
			justify-content: center;
			align-items: center;

			padding: 0 2px;
			width: 35px !important;
			height: 35px !important;
			font-size: 16px !important;

			&.is-active{
				background-color: #5E6A72;
				cursor: pointer;
				a{
					color: white;
				}
			}

			&:hover{
				background: #5E6A72 !important;
				cursor: pointer;
				a {
					color: white !important;
				}
			}

			a{
				text-decoration: none;
				top: 5px !important;
				color: $primary-font-color;
			}

		}

	}

}